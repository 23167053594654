

import { UNEMPLOYMENT_CONTACT_NOTIFICATION_DESCRIPTION, UNEMPLOYMENT_CONTACT_NOTIFICATION_HEADER, UNEMPLOYMENT_FAQ_DESCRIPTION, UNEMPLOYMENT_FAQ_SUBTITLE, UNEMPLOYMENT_FAQ_TITLE, UNEMPLOYMENT_HEADER_TITLE, UNEMPLOYMENT_HELMET_DESCRIPTION, UNEMPLOYMENT_HELMET_TITLE, UNEMPLOYMENT_MANAGE_SUBSCRIPTION_BUTTON, UNEMPLOYMENT_MANAGE_SUBSCRIPTION_INSTRUCTION, UNEMPLOYMENT_MANAGE_SUBSCRIPTION_TITLE, UNEMPLOYMENT_PRICING_DESCRIPTION, UNEMPLOYMENT_PRICING_FREE_CONSULTING, UNEMPLOYMENT_PRICING_FREE_CONSULTING_BUTTON_CONTACT, UNEMPLOYMENT_PRICING_FREE_CONSULTING_DESCRIPTION, UNEMPLOYMENT_PRICING_PLAN_SUBTITLE, UNEMPLOYMENT_PRICING_PLAN_TITLE, UNEMPLOYMENT_PRICING_PRO_BANKRUPTICIES, UNEMPLOYMENT_PRICING_PRO_BUTTON_SUBSCRIBE, UNEMPLOYMENT_PRICING_PRO_CHARGE_OFFS, UNEMPLOYMENT_PRICING_PRO_COLLECTION_CHARGES, UNEMPLOYMENT_PRICING_PRO_HARD_INQUIRIES, UNEMPLOYMENT_PRICING_PRO_LATE_PAYMENTS, UNEMPLOYMENT_PRICING_PRO_MEDICAL_BILLS, UNEMPLOYMENT_PRICING_PRO_PERSONAL_INFORMATION, UNEMPLOYMENT_PRICING_PRO_PUBLIC_RECORDS, UNEMPLOYMENT_PRICING_PRO_REPOSSESSIONS, UNEMPLOYMENT_PRICING_PRO_STUDENT_LOANS,  UNEMPLOYMENT_PRICING_TESTIMONIAL, UNEMPLOYMENT_PRICING_TITLE, UNEMPLOYMENT_PRICING_TRIAL_DESCRIPTION, UNEMPLOYMENT_PROCESS_ASSESTMENT_DESCRIPTION, UNEMPLOYMENT_PROCESS_ASSESTMENT_HEADER, UNEMPLOYMENT_PROCESS_COACH_DESCRIPTION, UNEMPLOYMENT_PROCESS_COACH_HEADER, UNEMPLOYMENT_PROCESS_DISPUTES_DESCRIPTION, UNEMPLOYMENT_PROCESS_DISPUTES_HEADER, UNEMPLOYMENT_PROCESS_HEADER, UNEMPLOYMENT_PROCESS_SIGUNP_DESCRIPTION, UNEMPLOYMENT_PROCESS_SIGUNP_HEADER, } from '../types'

import { UNEMPLOYMENT_FAQ_CAN_I_DO_IT_ANSWER, UNEMPLOYMENT_FAQ_CAN_I_DO_IT_QUESTION, UNEMPLOYMENT_FAQ_CAN_LINARES_GUARANTEE_RESULTS_ANSWER, UNEMPLOYMENT_FAQ_CAN_LINARES_GUARANTEE_RESULTS_QUESTION, UNEMPLOYMENT_FAQ_HOW_LONG_TAKE_ANSWER, UNEMPLOYMENT_FAQ_HOW_LONG_TAKE_QUESTION, UNEMPLOYMENT_FAQ_HOW_WORK_ANSWER, UNEMPLOYMENT_FAQ_HOW_WORK_QUESTION, UNEMPLOYMENT_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_ANSWER, UNEMPLOYMENT_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_QUESTION, UNEMPLOYMENT_FAQ_WHY_CREDIT_ITS_IMPORTANT_ANSWER, UNEMPLOYMENT_FAQ_WHY_CREDIT_ITS_IMPORTANT_QUESTION, UNEMPLOYMENT_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_ANSWER, UNEMPLOYMENT_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_QUESTION } from '../types'
const en_unemplyment_page = {//Credit Repair Page
    helmet: {
        [UNEMPLOYMENT_HELMET_TITLE]: "Credit Repair | Online Credit Card Repair and Payment Services in USA.",
        [UNEMPLOYMENT_HELMET_DESCRIPTION]: "Discover how our proven methods can help you unlock the secrets to a better credit score and achieve your goals. Learn more about the surprising impact of good credit on your life.",
    },
    section: {//Sections  within the Home page

        main: {//Main  Section of the Home Page
            [UNEMPLOYMENT_HEADER_TITLE]: 'Credit Repair Program',
        },
        pricing: {
            [UNEMPLOYMENT_PRICING_TITLE]: 'Discover excellent workplaces',
            [UNEMPLOYMENT_PRICING_DESCRIPTION]: 'Unlock more than 10 powerful features and enjoy a complimentary consultation with our standard subscription. Sign up with confidence today!',
            [UNEMPLOYMENT_PRICING_TRIAL_DESCRIPTION]: 'Get a free trial for 14 days.',
            //Plan Details
            [UNEMPLOYMENT_PRICING_PLAN_TITLE]:'The Right Plan for',
            [UNEMPLOYMENT_PRICING_PLAN_SUBTITLE]:'Your Needs!',
            [UNEMPLOYMENT_PRICING_PRO_LATE_PAYMENTS]: 'LATE PAYMENTS',
            [UNEMPLOYMENT_PRICING_PRO_HARD_INQUIRIES]: 'HARD INQUIRIES',
            [UNEMPLOYMENT_PRICING_PRO_PERSONAL_INFORMATION]: 'PERSONAL INFORMATION',
            [UNEMPLOYMENT_PRICING_PRO_COLLECTION_CHARGES]: 'COLLECTION CHARGES',
            [UNEMPLOYMENT_PRICING_PRO_CHARGE_OFFS]: 'CHARGE-OFFS',
            [UNEMPLOYMENT_PRICING_PRO_MEDICAL_BILLS]: 'MEDICAL BILLS',
            [UNEMPLOYMENT_PRICING_PRO_STUDENT_LOANS]: 'STUDENT LOANS',
            [UNEMPLOYMENT_PRICING_PRO_REPOSSESSIONS]: 'REPOSSESSIONS',
            [UNEMPLOYMENT_PRICING_PRO_BANKRUPTICIES]: 'BANKRUPTCIES',
            [UNEMPLOYMENT_PRICING_PRO_PUBLIC_RECORDS]: 'PUBLIC RECORDS',
            [UNEMPLOYMENT_PRICING_PRO_BUTTON_SUBSCRIBE]: 'Start today',

            //Free Consulting
            [UNEMPLOYMENT_PRICING_FREE_CONSULTING]: 'FREE CONSULTING',
            [UNEMPLOYMENT_PRICING_FREE_CONSULTING_BUTTON_CONTACT]: 'Contact us',
            [UNEMPLOYMENT_PRICING_FREE_CONSULTING_DESCRIPTION]: ` If you're looking for guidance or advice, free consulting is
            an excellent resource that can provide you with the help you need.`,

            [UNEMPLOYMENT_PRICING_TESTIMONIAL]: `"I am extremely satisfied with the credit repair service provided by Linares Pro Corp Family Service. 
            They were able to help me improve my credit score significantly within a short period of time. Their team was very professional, knowledgeable and responsive to all my queries. 
            I highly recommend their service to anyone looking to repair their credit."`
        },
        process: {
            //Process Steps
            [UNEMPLOYMENT_PROCESS_HEADER]: 'How does this work?',
            [UNEMPLOYMENT_PROCESS_SIGUNP_HEADER]: 'Subscribe Now',
            [UNEMPLOYMENT_PROCESS_SIGUNP_DESCRIPTION]: "Take your first step towards achieving your financial goals by enrolling in our credit repair program.",
            [UNEMPLOYMENT_PROCESS_COACH_HEADER]: 'Who will be your guide?',
            [UNEMPLOYMENT_PROCESS_COACH_DESCRIPTION]: 'With registration complete, a knowledgeable guide will be there to assist you every step of the way, providing support and addressing any concerns you may have about the credit repair process.',
            [UNEMPLOYMENT_PROCESS_ASSESTMENT_HEADER]: "Credit Assessment",
            [UNEMPLOYMENT_PROCESS_ASSESTMENT_DESCRIPTION]: "After you have familiarized yourself with your guide, we will be able to thoroughly analyze your credit report and pinpoint the factors that are negatively impacting your credit score. Then, we will work collaboratively to help you comprehend your credit profile and identify the areas that require improvement. Our objective is to provide you with the necessary guidance and assistance to achieve a higher credit score and enhance your financial health",
            [UNEMPLOYMENT_PROCESS_DISPUTES_HEADER]: "Disputes",
            [UNEMPLOYMENT_PROCESS_DISPUTES_DESCRIPTION]: "You can be confident that in the event of any negative impact on your credit score, we will take prompt action to dispute it with the credit bureau. Our team of experts will tenaciously challenge any unfair or incorrect elements and work tirelessly to negotiate your removal from your credit report. You can trust us to fight for your rights and ensure that your credit score remains strong."

        },
        subscription:{
            [UNEMPLOYMENT_MANAGE_SUBSCRIPTION_TITLE]:'Do you already have a subscription?',
            [UNEMPLOYMENT_MANAGE_SUBSCRIPTION_INSTRUCTION]:'To check your subscription, please click the button below and enter your email address. You will receive an email with instructions on how to log in to your account and view your subscription details. Thank you!',
            [UNEMPLOYMENT_MANAGE_SUBSCRIPTION_BUTTON]:'Manage Subscription',
        }

    },
    faqs: {
        [UNEMPLOYMENT_FAQ_TITLE]:'Questions?',
        [UNEMPLOYMENT_FAQ_SUBTITLE]:"We’ve Got You Covered",
        [UNEMPLOYMENT_FAQ_DESCRIPTION]: ` Welcome to our Frequently Asked Questions (FAQ) section! We understand that you may have questions about our services and we're here to help.
        Below, you'll find answers to some of the most commonly asked questions we receive.
        If you can't find the information you're looking for, please don't hesitate to reach out to us.
        Our team is always happy to assist you in any way we can.`,
  
        [UNEMPLOYMENT_FAQ_HOW_WORK_QUESTION]: "How does it work?",
        [UNEMPLOYMENT_FAQ_HOW_WORK_ANSWER]: "Our team will review your credit reports, disputes errors and negative information with credit bureaus, and negotiates with creditors to remove or modify negative items.",
        [UNEMPLOYMENT_FAQ_HOW_LONG_TAKE_QUESTION]: 'How long does it take to see results?',
        [UNEMPLOYMENT_FAQ_HOW_LONG_TAKE_ANSWER]: "The time required to observe results for credit repair can vary depending on various factors, including the number of negative items, the complexity of the dispute process, and the client's adherence to the credit repair process. Typically, it may take 2 to 7 months to witness noticeable enhancements.",
        [UNEMPLOYMENT_FAQ_CAN_I_DO_IT_QUESTION]: 'Can I repair my own credit?',
        [UNEMPLOYMENT_FAQ_CAN_I_DO_IT_ANSWER]: "Yes, you can repair your own credit. However, it can be a time-consuming process that requires a thorough understanding of credit reporting laws and regulations. Many people choose to work with a credit repair company to save time and ensure that their credit is repaired properly.",
        [UNEMPLOYMENT_FAQ_CAN_LINARES_GUARANTEE_RESULTS_QUESTION]: "Can Linares Credit Repair guarantee results?",
        [UNEMPLOYMENT_FAQ_CAN_LINARES_GUARANTEE_RESULTS_ANSWER]: "No, Linares Credit Repair cannot guarantee that negative items will be removed from your credit report. However, we can help you dispute inaccurate or unverifiable information on your credit report, which can potentially result in those negative items being removed.",
        [UNEMPLOYMENT_FAQ_WHY_CREDIT_ITS_IMPORTANT_QUESTION]: "Why is credit repair important?",
        [UNEMPLOYMENT_FAQ_WHY_CREDIT_ITS_IMPORTANT_ANSWER]: "Credit repair is important because a good credit score is a crucial factor in obtaining loans, mortgages, and credit cards with favorable interest rates and terms. A negative credit history can make it difficult or impossible to get approved for these financial products and can result in higher interest rates and fees.",
        [UNEMPLOYMENT_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_QUESTION]: "Will disputing errors on my credit report hurt my credit score?",
        [UNEMPLOYMENT_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_ANSWER]: "No, disputing errors on your credit report will not hurt your credit score. In fact, correcting errors on your credit report can help improve your credit score.",
        [UNEMPLOYMENT_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_QUESTION]: "What is a good credit score?",
        [UNEMPLOYMENT_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_ANSWER]: "A good credit score is typically between 700 and 850. A higher score indicates better financial health."


    },
    contact: {
        [UNEMPLOYMENT_CONTACT_NOTIFICATION_HEADER]: "Do you require further assistance?",
        [UNEMPLOYMENT_CONTACT_NOTIFICATION_DESCRIPTION]: "Call Now!"
    }
}

export default en_unemplyment_page;

