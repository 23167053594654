import {
    Box,
    Text,
    List,
    ListItem,
    ListIcon,
    Button,
    Divider,
    Stack,
    Heading,
    Image,
    useColorMode,
    Flex

} from '@chakra-ui/react'


import { FaCheckCircle, } from 'react-icons/fa'
import { CR_PRICING_FREE_CONSULTING,  CR_PRICING_FREE_CONSULTING_DESCRIPTION, CR_PRICING_PLAN_SUBTITLE, CR_PRICING_PLAN_TITLE, CR_PRICING_PRO_BANKRUPTICIES, CR_PRICING_PRO_BUTTON_SUBSCRIBE, CR_PRICING_PRO_CHARGE_OFFS, CR_PRICING_PRO_COLLECTION_CHARGES, CR_PRICING_PRO_HARD_INQUIRIES, CR_PRICING_PRO_LATE_PAYMENTS, CR_PRICING_PRO_MEDICAL_BILLS, CR_PRICING_PRO_PERSONAL_INFORMATION, CR_PRICING_PRO_PUBLIC_RECORDS, CR_PRICING_PRO_REPOSSESSIONS, CR_PRICING_PRO_STUDENT_LOANS} from '../../locales/types'
import { Trans } from 'react-i18next'


import questionImage from '@images/credit_repair/questions.svg'
const transPath = "page.credit_repair.section.pricing"
const proPlanListItems = [
    {
        id: '_01_',
        description: CR_PRICING_PRO_LATE_PAYMENTS,
        iconColor: '#04b306'
    },
    {
        id: '_02_',
        description: CR_PRICING_PRO_HARD_INQUIRIES,
        iconColor: '#04b306'

    },
    {
        id: '_03_',
        description: CR_PRICING_PRO_PERSONAL_INFORMATION,
        iconColor: '#04b306'
    },
    {
        id: '_04_',
        description: CR_PRICING_PRO_COLLECTION_CHARGES,
        iconColor: '#04b306'
    },
    {
        id: '_05_',
        description: CR_PRICING_PRO_CHARGE_OFFS,
        iconColor: '#04b306'
    },
    {
        id: '_06_',
        description: CR_PRICING_PRO_MEDICAL_BILLS,
        iconColor: '#04b306'
    },
    {
        id: '_07_',
        description: CR_PRICING_PRO_STUDENT_LOANS,
        iconColor: '#04b306'
    },
    {
        id: '_08_',
        description: CR_PRICING_PRO_REPOSSESSIONS,

        iconColor: '#04b306'
    },
    {
        id: '_09_',
        description: CR_PRICING_PRO_BANKRUPTICIES,


        iconColor: '#04b306'
    },
    {
        id: '_10_',
        description: CR_PRICING_PRO_PUBLIC_RECORDS,
        iconColor: "#04b306"
    }

]



const CreditRepairPrice = () => {
    const handleRedirectToSubscription = () => {
        window.location.href = 'https://myfreescorenow.com/enroll/?AID=LinaresprofamilyserviceCorp&PID=87207'
    }

    const handleCallUs = () => {
        window.location.href = 'tel:+17869726285'
    }
    return (
        <Box py={6} px={5} my={30} width="full" position={'relative'} as='section' id='pricing-container'>
            <Stack spacing={4} width={'100%'} direction={'column'}>
                <Stack
                    p={5}
                    alignItems={'center'}
                    justifyContent={{
                        base: 'flex-start',
                        md: 'space-around',
                    }}
                    direction={{
                        base: 'column',
                        md: 'row',
                    }}>
                    <Stack
                        width={{
                            base: '100%',
                            md: '40%',
                        }}
                        textAlign={'center'}>
                        <Heading size={{base:'sm',lg:'lg'}}>
                        <Trans i18nKey={`${transPath}.${CR_PRICING_PLAN_TITLE}`} /><Text color="purple.400">  <Trans i18nKey={`${transPath}.${CR_PRICING_PLAN_SUBTITLE}`} /></Text>
                        </Heading>
                    </Stack>

                </Stack>
                <Divider />
                <PackageTier image={questionImage} title={<Trans i18nKey={`${transPath}.${CR_PRICING_FREE_CONSULTING}`} />} typePlan="Free" description={<Trans i18nKey={`${transPath}.${CR_PRICING_FREE_CONSULTING_DESCRIPTION}`} />} options={[]} />
                <Divider />
                <PackageTier action={{ action: handleCallUs, title: <Trans i18nKey={`${transPath}.${CR_PRICING_PRO_BUTTON_SUBSCRIBE}`} /> }} checked title={'Pro'} typePlan="$44.99" options={proPlanListItems} />
               
            </Stack>
        </Box>
    )
}


const PackageTier = ({ title, options, description, image, action, typePlan, checked = false }) => {
    const colorTextLight = checked ? 'white' : 'purple.600'
    const colorTextDark = checked ? 'white' : 'purple.500'
    const mode = useColorMode();
    return (
        <Stack
            p={3}
            py={3}
            justifyContent={{
                base: 'flex-start',
                md: 'space-around',
            }}
            direction={{
                base: 'column',
                md: 'row',
            }}
            alignItems={{ md: 'center' }}>
            <Heading size={'md'}>{title}</Heading>
            {!!description ? <Text maxW={'sm'}>
                {description}
            </Text> : (<List spacing={3} textAlign="start">
                {options.map(({ description }, index) => (
                    <ListItem key={index * 10 + description}>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        <Trans i18nKey={`${transPath}.${description}`} />
                    </ListItem>
                ))}
            </List>)}
            <Flex flexDirection='column'>
            <Heading size={'xl'}>{typePlan}</Heading>
            {image && <Image src={image} objectFit={'contain'} boxSize={'sm'}/>}
            </Flex>
           
            {!!action && <Stack>
                <Button
                    fontSize={'larger'}
                    textTransform={'uppercase'}
                    onClick={() => action?.action()}
                    size="lg"
                    color={mode === 'dark' ? colorTextLight : colorTextDark}
                    bgColor={mode === 'dark' ? '#2dff1a' : '#20b300'}>
                    {action?.title}
                </Button>
            </Stack>}
        </Stack>
    )
}


export default CreditRepairPrice;
