

import { CR_CONTACT_NOTIFICATION_DESCRIPTION, CR_CONTACT_NOTIFICATION_HEADER, CR_FAQ_CAN_I_DO_IT_ANSWER, CR_FAQ_CAN_I_DO_IT_QUESTION, CR_FAQ_CAN_LINARES_GUARANTEE_RESULTS_ANSWER, CR_FAQ_CAN_LINARES_GUARANTEE_RESULTS_QUESTION, CR_FAQ_DESCRIPTION, CR_FAQ_HOW_LONG_TAKE_ANSWER, CR_FAQ_HOW_LONG_TAKE_QUESTION, CR_FAQ_HOW_WORK_ANSWER, CR_FAQ_HOW_WORK_QUESTION, CR_FAQ_SUBTITLE, CR_FAQ_TITLE, CR_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_ANSWER, CR_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_QUESTION, CR_FAQ_WHY_CREDIT_ITS_IMPORTANT_ANSWER, CR_FAQ_WHY_CREDIT_ITS_IMPORTANT_QUESTION, CR_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_ANSWER, CR_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_QUESTION, CR_FREE_SCORE_BUTTON, CR_FREE_SCORE_DESCRIPTION, CR_FREE_SCORE_DISCLAIMER, CR_FREE_SCORE_REDIRECT_ALERT_BUTTON, CR_FREE_SCORE_REDIRECT_ALERT_CANCEL_BUTTON, CR_FREE_SCORE_REDIRECT_ALERT_DESCRIPTION, CR_FREE_SCORE_REDIRECT_NOTIFICATION, CR_FREE_SCORE_TITLE, CR_HEADER_TITLE, CR_HELMET_DESCRIPTION, CR_HELMET_TITLE, CR_MANAGE_SUBSCRIPTION_BUTTON, CR_MANAGE_SUBSCRIPTION_INSTRUCTION, CR_MANAGE_SUBSCRIPTION_TITLE, CR_PRICING_DESCRIPTION, CR_PRICING_FREE_CONSULTING, CR_PRICING_FREE_CONSULTING_BUTTON_CONTACT, CR_PRICING_FREE_CONSULTING_DESCRIPTION, CR_PRICING_PLAN_SUBTITLE, CR_PRICING_PLAN_TITLE, CR_PRICING_PRO_BANKRUPTICIES, CR_PRICING_PRO_BUTTON_SUBSCRIBE, CR_PRICING_PRO_CHARGE_OFFS, CR_PRICING_PRO_COLLECTION_CHARGES, CR_PRICING_PRO_HARD_INQUIRIES, CR_PRICING_PRO_LATE_PAYMENTS, CR_PRICING_PRO_MEDICAL_BILLS, CR_PRICING_PRO_PERSONAL_INFORMATION, CR_PRICING_PRO_PUBLIC_RECORDS, CR_PRICING_PRO_REPOSSESSIONS, CR_PRICING_PRO_STUDENT_LOANS, CR_PRICING_TESTIMONIAL, CR_PRICING_TITLE, CR_PRICING_TRIAL_DESCRIPTION, CR_PROCESS_ASSESTMENT_DESCRIPTION, CR_PROCESS_ASSESTMENT_HEADER, CR_PROCESS_COACH_DESCRIPTION, CR_PROCESS_COACH_HEADER, CR_PROCESS_DISPUTES_DESCRIPTION, CR_PROCESS_DISPUTES_HEADER, CR_PROCESS_HEADER, CR_PROCESS_SIGUNP_DESCRIPTION, CR_PROCESS_SIGUNP_HEADER, } from '../types'


const es_CR_page = {//Credit Repair Page
    helmet: {//Helmet Metadata
        [CR_HELMET_TITLE]: "Reparación de crédito | Servicios de reparación y pago de tarjetas de crédito en línea en EE. UU.",
    },
    section: {//Sections  within the Home page
        main: {//Main  Section of the Home Page
            [CR_HEADER_TITLE]: 'Programa de Reparación de Crédito',
            [CR_HELMET_DESCRIPTION]: "Reparación de Crédito. Descubra cómo nuestros métodos comprobados pueden ayudarlo a descubrir los secretos para un mejor puntaje de crédito y alcanzar sus metas. Obtenga más información sobre el sorprendente impacto de un buen crédito en su vida.",

        },
        pricing: {
            [CR_PRICING_TITLE]: 'Repare su crédito rápidamente',
            [CR_PRICING_DESCRIPTION]: 'Desbloquea más de 10 potentes prestaciones y disfruta de consultas gratis con nuestra suscripción estándar. ¡Suscríbete hoy!',
            [CR_PRICING_TRIAL_DESCRIPTION]: '14 días de prueba gratuita.',
            //Plan Details
            [CR_PRICING_PLAN_TITLE]: '¡El plan adecuado para',
            [CR_PRICING_PLAN_SUBTITLE]: 'Tus necesidades!',
            [CR_PRICING_PRO_LATE_PAYMENTS]: 'PAGOS ATRASADO',
            [CR_PRICING_PRO_HARD_INQUIRIES]: 'VERIFICACIONES DE CRÉDITO',
            [CR_PRICING_PRO_PERSONAL_INFORMATION]: 'INFORMACIÓN PERSONAL',
            [CR_PRICING_PRO_COLLECTION_CHARGES]: 'CARGOS DE COLECTORES',
            [CR_PRICING_PRO_CHARGE_OFFS]: 'CANCELACIONES',
            [CR_PRICING_PRO_MEDICAL_BILLS]: 'FACTURAS MÉDICAS',
            [CR_PRICING_PRO_STUDENT_LOANS]: 'PRESTAMOS ESTUDIANTIL',
            [CR_PRICING_PRO_REPOSSESSIONS]: 'EMBARGOS',
            [CR_PRICING_PRO_BANKRUPTICIES]: 'BANCARROTAS',
            [CR_PRICING_PRO_PUBLIC_RECORDS]: 'REGISTROS PÚBLICOS',
            [CR_PRICING_PRO_BUTTON_SUBSCRIBE]: 'Llámanos Ahora',


            //Free Consulting
            [CR_PRICING_FREE_CONSULTING]: 'Consultas Gratuita',
            [CR_PRICING_FREE_CONSULTING_BUTTON_CONTACT]: 'Contáctanos',
            [CR_PRICING_FREE_CONSULTING_DESCRIPTION]: `Si busca orientación o asesoramiento, la consultoría gratuita es un excelente recurso que puede brindarle la ayuda que necesita.`,

            [CR_PRICING_TESTIMONIAL]: `"Estoy muy satisfecho con el servicio de reparación de crédito brindado por Linares Pro Corp Family Service. 
            Pudieron ayudarme a mejorar significativamente mi puntaje de crédito en un corto período de tiempo. Su equipo fue muy profesional, conocedor y receptivo a todas mis consultas. 
            Recomiendo encarecidamente su servicio a cualquiera que busque reparar su crédito".`
        },
        process: {
            //Process Steps
            [CR_PROCESS_HEADER]: '¿Cómo funciona?',
            [CR_PROCESS_SIGUNP_HEADER]: 'Suscríbete ahora',
            [CR_PROCESS_SIGUNP_DESCRIPTION]: "Dé su primer paso hacia el logro de sus metas financieras inscribiéndose en nuestro programa de reparación de crédito.",
            [CR_PROCESS_COACH_HEADER]: '¿Quién será tu guía?',
            [CR_PROCESS_COACH_DESCRIPTION]: 'Una vez completado el registro, un guía experto estará allí para ayudarlo en cada paso del camino, brindándole apoyo y abordando cualquier inquietud que pueda tener sobre el proceso de reparación de crédito.',
            [CR_PROCESS_ASSESTMENT_HEADER]: "Evaluación crediticia",
            [CR_PROCESS_ASSESTMENT_DESCRIPTION]: "Una vez que se haya familiarizado con su guía, podremos analizar a fondo su informe de crédito e identificar los factores que están afectando negativamente su puntaje de crédito. Luego, trabajaremos en colaboración para ayudarlo a comprender su perfil crediticio e identificar las áreas que requieren mejoras. Nuestro objetivo es brindarle la orientación y asistencia necesarias para lograr un puntaje crediticio más alto y mejorar su salud financiera.",
            [CR_PROCESS_DISPUTES_HEADER]: "Disputas",
            [CR_PROCESS_DISPUTES_DESCRIPTION]: "Puede estar seguro de que en caso de cualquier impacto negativo en su puntaje de crédito, tomaremos medidas inmediatas para disputarlo con la agencia de crédito. Nuestro equipo de expertos desafiará tenazmente cualquier elemento injusto o incorrecto y trabajará incansablemente para negociar su eliminación de su informe de crédito. Puede confiar en nosotros para luchar por sus derechos y garantizar que su puntaje de crédito se mantenga sólido."

        },
        freescore: {
            [CR_FREE_SCORE_TITLE]: '¡Obtén los 3 informes y las 3 puntuaciones ahora!',
            [CR_FREE_SCORE_DESCRIPTION]: 'Obtenga su informe de crédito y puntaje de crédito de Experian, Equifax y TransUnion. Nuestro servicio es 100% rápido, fácil y seguro.',
            [CR_FREE_SCORE_BUTTON]: 'Comience Ahora',
            [CR_FREE_SCORE_DISCLAIMER]: `*Al hacer clic en "Comenzar ahora", usted autoriza a Linares Pro Corp Family Service a obtener su informe de crédito y puntaje de crédito de Experian, Equifax y TransUnion. 
            Usted acepta nuestros Términos de Uso y Política de Privacidad. Revisar su informe de crédito no afectará su puntaje de crédito. También recibirá una consulta gratuita de nuestros expertos en reparación de crédito. Este es un servicio de terceros proporcionado por MyFreeScoreNow.com. Linares Pro Corp Family Service no se hace responsable de la exactitud de la información proporcionada por MyFreeScoreNow.com. 
            Para obtener más información, consulte los Términos de uso y la Política de privacidad de MyFreeScoreNow.com.`,
            [CR_FREE_SCORE_REDIRECT_NOTIFICATION]: '¡Redirigiendo a MyFreeScoreNow.com!',
            [CR_FREE_SCORE_REDIRECT_ALERT_DESCRIPTION]: 'Ahora está abandonando el sitio web de Linares Pro Corp Family Service y será redirigido a MyFreeScoreNow.com. Haga clic en "Aceptar" para continuar.',
            [CR_FREE_SCORE_REDIRECT_ALERT_BUTTON]: 'Aceptar',
            [CR_FREE_SCORE_REDIRECT_ALERT_CANCEL_BUTTON]: 'Cancelar',
        },
        subscription: {
            [CR_MANAGE_SUBSCRIPTION_TITLE]: '¿Ya tienes una suscripción?',
            [CR_MANAGE_SUBSCRIPTION_INSTRUCTION]: 'Para verificar su suscripción, haga clic en el botón de abajo e ingrese su dirección de correo electrónico. Recibirá un correo electrónico con instrucciones sobre cómo iniciar sesión en su cuenta y ver los detalles de su suscripción. ¡Gracias!',
            [CR_MANAGE_SUBSCRIPTION_BUTTON]: 'Administrar la suscripción',
        }

    },
    faqs: {
        [CR_FAQ_TITLE]: '¿Preguntas?',
        [CR_FAQ_SUBTITLE]: "Te tenemos cubierto",
        [CR_FAQ_DESCRIPTION]: `¡Bienvenido a nuestra sección de Preguntas Frecuentes (FAQ) !Entendemos que puede tener preguntas sobre nuestros servicios y estamos aquí para ayudarlo.
        A continuación, encontrará respuestas a algunas de las preguntas más frecuentes que recibimos.
        Si no encuentras la información que buscas, no dudes en ponerte en contacto con nosotros.
        Nuestro equipo siempre estará encantado de ayudarle en todo lo que podamos.`,
        [CR_FAQ_HOW_WORK_QUESTION]: "¿Cómo funciona?",
        [CR_FAQ_HOW_WORK_ANSWER]: "Nuestro equipo revisará sus informes de crédito, disputará errores e información negativa con las agencias de crédito, y negociará con los acreedores para eliminar o modificar elementos negativos.",
        [CR_FAQ_HOW_LONG_TAKE_QUESTION]: '¿Cuánto tiempo se tarda en ver resultados?',
        [CR_FAQ_HOW_LONG_TAKE_ANSWER]: "El tiempo requerido para observar los resultados de la reparación de crédito puede variar dependiendo de varios factores, incluyendo la cantidad de elementos negativos, la complejidad del proceso de disputa y la adherencia del cliente al proceso de reparación de crédito. Por lo general, puede tomar de 2 a 7 meses presenciar mejoras notables.",
        [CR_FAQ_CAN_I_DO_IT_QUESTION]: '¿Puedo reparar mi propio crédito?',
        [CR_FAQ_CAN_I_DO_IT_ANSWER]: "Sí, puede reparar su propio crédito. Sin embargo, puede ser un proceso lento que requiere una comprensión profunda de las leyes y regulaciones de informes crediticios. Muchas personas optan por trabajar con una compañía de reparación de crédito para ahorrar tiempo y asegurarse de que su crédito se repare correctamente.",
        [CR_FAQ_CAN_LINARES_GUARANTEE_RESULTS_QUESTION]: "¿Puede Linares Credit Repair garantizar los resultados?",
        [CR_FAQ_CAN_LINARES_GUARANTEE_RESULTS_ANSWER]: "No, Linares Credit Repair cannot guarantee that negative items will be removed from your credit report. However, we can help you dispute inaccurate or unverifiable information on your credit report, which can potentially result in those negative items being removed.",
        [CR_FAQ_WHY_CREDIT_ITS_IMPORTANT_QUESTION]: "¿Por qué es importante la reparación de crédito?",
        [CR_FAQ_WHY_CREDIT_ITS_IMPORTANT_ANSWER]: "La reparación de crédito es importante porque un buen puntaje de crédito es un factor crucial para obtener préstamos, hipotecas y tarjetas de crédito con tasas de interés y términos favorables. Un historial crediticio negativo puede dificultar o imposibilitar la aprobación de estos productos financieros y puede resultar en tasas de interés y tarifas más altas.",
        [CR_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_QUESTION]: "¿Disputar errores en mi informe de crédito afectará mi puntaje de crédito?",
        [CR_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_ANSWER]: "No, disputar errores en su informe de crédito no perjudicará su puntaje de crédito. De hecho, corregir errores en su informe de crédito puede ayudar a mejorar su puntaje de crédito.",
        [CR_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_QUESTION]: "¿Qué es un buen puntaje de crédito?",
        [CR_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_ANSWER]: "Un buen puntaje de crédito suele estar entre 700 y 850. Una puntuación más alta indica una mejor salud financiera."


    },
    contact: {
        [CR_CONTACT_NOTIFICATION_HEADER]: "¿Necesitas más ayuda?",
        [CR_CONTACT_NOTIFICATION_DESCRIPTION]: "¡Llame ahora!"
    }
}

export default es_CR_page;

